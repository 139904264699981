import React from "react";
import LocationSettingsItem from "./locationSettingsItem";
import PdfFillDoc from "./pdfFillDoc";
import SplitScreen from "./splitScreen";
import GradingDoc from "./gradingDoc";

import TypesOfLocationEnum from "./typesOfLocationEnum";

const LocationsForExam = ({ updateCurrentViewer, isProgramming, pdfExists, currentViewer }) => {
    return (
        <>
            <div className="location-settings-hover">
                {
                    pdfExists && (currentViewer === TypesOfLocationEnum.CUSTOM_EXAM || currentViewer === TypesOfLocationEnum.SPLIT_SCREEN)

                    ?

                    <LocationSettingsItem svgOne={<PdfFillDoc />} updateCurrentViewer={updateCurrentViewer} path={TypesOfLocationEnum.PDF}/>

                    :

                    <>
                    </>
                }
                {
                    isProgramming && (currentViewer === TypesOfLocationEnum.PDF || currentViewer === TypesOfLocationEnum.SPLIT_SCREEN)

                    ?

                    <>
                        <LocationSettingsItem svgOne={
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 16 16">
                                <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0M9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1M4.5 9a.5.5 0 0 1 0-1h7a.5.5 0 0 1 0 1zM4 10.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5m.5 2.5a.5.5 0 0 1 0-1h4a.5.5 0 0 1 0 1z"/>
                            </svg>
                        } updateCurrentViewer={updateCurrentViewer} path={TypesOfLocationEnum.CUSTOM_EXAM}/>
                    </>

                    :

                    <>
                    </>
                }
                {
                    isProgramming && pdfExists && (currentViewer !== TypesOfLocationEnum.SPLIT_SCREEN)

                    ?

                    <>
                        <LocationSettingsItem svgOne={<SplitScreen />} updateCurrentViewer={updateCurrentViewer} path={TypesOfLocationEnum.SPLIT_SCREEN}/>
                    </>

                    :

                    <>
                    </>
                }
            </div>
        </>
    );
}

export default LocationsForExam;