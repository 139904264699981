import JSZip from "jszip";

async function createZipFromObject(data) {
    const zip = new JSZip();

    Object.keys(data).forEach((path) => {
        const { isDir, content } = data[path];

        if (isDir) {
            // If it's a directory, create a folder in the ZIP
            zip.folder(path);
        } else {
            // If it's a file, add the content
            zip.file(path, content || "");
        }
    });

    // Generate the ZIP file in memory as a Blob
    const zipContent = await zip.generateAsync({ type: "blob" });
    
    // Return the Blob (you can send this to the backend)
    return zipContent;
}

export default createZipFromObject;