import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import NavTakeEvaluation from "./navTakeEvaluation";
import parseFileStructure from "./parseFileStructure";
import parseZipFile from "./parseZipFile";
import TypesOfLocationEnum from "./typesOfLocationEnum";
import LocationsForExam from "./locationsForExam";
import PdfTakeEval from "./pdfTakeEval";
import CustomExamExtendedWrapperView from "./customExamExtendedWrapperView";
import SplitScreenView from "./splitScreenView";
import fetchWithPreSigned from "./fetchWithPreSigned";
import TypesOfQuestionsEnum from "./typesOfQuestionsEnum";

const TakeEvaluation = () => {
    const navigate = useNavigate();
    const { courseCode, assessmentCode } = useParams();
    const [requestLifeCycle, setRequestLifeCycle] = useState(false);
    const [requestLifeCycleTwo, setRequestLifeCycleTwo] = useState(false);
    const [requestLifeCycleThree, setRequestLifeCycleThree] = useState(false);
    const [requestLifeCycleFour, setRequestLifeCycleFour] = useState(false);
    const [zipFileExists, setZipFileExists] = useState(false);
    const [pdfExists, setPdfExists] = useState(false)
    const [questions, setQuestions] = useState([]);
    const [contents, setContents] = useState(null);
    const [questionsToSave, setQuestionsToSave] = useState({});
    const [currentViewer, setCurrentViewer] = useState(TypesOfLocationEnum.CUSTOM_EXAM);
    const [pdf, setPdf] = useState("");
    const [contentsNeedToBeSaved, setContentsNeedToBeSaved] = useState(false);
    const [isProgramming, setIsProgramming] = useState(false);
    const [useSyntaxHighlighting, setUseSyntaxHighlighting] = useState(false);
    const [inKioskParent, setInKioskParent] = useState(false);
    const [testsConfigDict, setTestsConfigDict] = useState({});
    const [error, setError] = useState(null);
    const [attempt, setAttempt] = useState(null);
    const [status, setStatus] = useState(null);
    const [allowedAttemptsProgramming, setAllowedAttemptsProgramming] = useState(null);
    const [coupledProgrammingQuestions, setCoupledProgrammingQuestions] = useState([]);
    const [fetchError, setFetchError] = useState(null);
    const [times, setTimes] = useState({
        startTime: "",
        endTime: ""
    })
    const [isDarkTheme, setIsDarkTheme] = useState(true);

    const isDesktop = process.env.REACT_APP_IS_DESKTOP === "true";
    
    useEffect(() => {
        const fetchTheme = async () => {
            try {
                const response = await fetch( process.env.REACT_APP_SUBMISSION_API_URL + `/get-theme`, {
                    method: "GET",
                    credentials: "include"
                });
                const data = await response.json();
                if (response.status === 200) {
                    setIsDarkTheme(data.isDarkTheme);
                } else if (response.status === 401) {
                    if (courseCode && assessmentCode && isDesktop) {
                        const queryParams = new URLSearchParams({
                            courseCode,
                            assessmentCode,
                        }).toString();
        
                        navigate(`/login?${queryParams}`);
                    } else {
                        window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
                    }
                }
            } catch (error) {
                console.log(error);
            }
        };
        
        fetchTheme();
    }, [])

    function updateIsDarkTheme(theme) {
        submitForm(theme);
        setIsDarkTheme(theme);        
    }

    const submitForm = async (theme) => {
        try {
            const url = process.env.REACT_APP_SUBMISSION_API_URL  + "/update-theme";
            const urlOptions = {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    isDarkTheme: theme
                })
            }

            const response = await fetch(url, urlOptions);
            if (response.status === 401) {
                if (courseCode && assessmentCode && isDesktop) {
                    const queryParams = new URLSearchParams({
                        courseCode,
                        assessmentCode,
                    }).toString();
    
                    navigate(`/login?${queryParams}`);                
                } else {
                    window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
                }
            }

        } catch(error) {
            console.log(error);
        }
    }

    const fetchUseLockdownSetting = async () => {
        const array = [false, false]

        try {
            const url = process.env.REACT_APP_EVALUATION_API_URL + `/get-lockdown-setting/${courseCode}/${assessmentCode}`

            const urlOptions = {
                method: "GET",
                credentials: "include"
            }

            const response = await fetch(url, urlOptions);
            const data = await response.json();
            if (response.status === 200) {
                array[0] = true
                array[1] = data.useLockdownEnvironment
            } else if (response.status === 401) {
                if (courseCode && assessmentCode && isDesktop) {
                    const queryParams = new URLSearchParams({
                        courseCode,
                        assessmentCode,
                    }).toString();
    
                    navigate(`/login?${queryParams}`);                
                } else {
                    window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
                }
            }

        } catch (error) {
            console.log(error)
        }

        return array
    }

    const fetchSetting = async () => {
        const [isValidResponse, isUsingLockdown] = await fetchUseLockdownSetting();
        
        if (!isValidResponse) {
            return false
        }
        
        if (isDesktop) {
            window.electron.setKioskMode(isUsingLockdown);
        }
        return isUsingLockdown;
    }

    useEffect(() => {
        const fetchData = async () => {
            const inKiosk = await fetchSetting();
            setInKioskParent(inKiosk);
        };
        fetchData();
    }, []);

    useEffect(() => {
        const getCustomExamStudent = async () => {
            try {
                setRequestLifeCycle(true);
                const response = await fetch( process.env.REACT_APP_EVALUATION_API_URL  + `/get-custom-exam-student/${courseCode}/${assessmentCode}`, {
                    method: "GET",
                    credentials: "include"
                });
                const data = await response.json();
                if (response.status === 200) {
                    // set the data
                    setQuestions(data.questionsInOrder);
                    setZipFileExists(data.zipFileExists);
                    setPdfExists(data.pdfExists);
                    setIsProgramming(data.isProgramming);
                    setUseSyntaxHighlighting(data.useSyntaxHighlighting);
                    setAllowedAttemptsProgramming(data.allowedAttemptsProgramming);                    
                    setTimes({
                        finalTime: data.finalTime,
                        duration: data.duration
                    })
                } else if (response.status === 401) {
                    if (courseCode && assessmentCode && isDesktop) {
                        const queryParams = new URLSearchParams({
                            courseCode,
                            assessmentCode,
                        }).toString();
        
                        navigate(`/login?${queryParams}`);                
                    } else {
                        window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
                    }
                } else if (response.status === 450 || response.status === 451 || response.status === 452 || response.status === 453 || response.status === 455) {
                    if (isDesktop) {
                        window.electron.setKioskMode(false);
                    }
                    navigate(`/${courseCode}/${assessmentCode}`);
                } else if (response.status === 454) {
                    if (courseCode && assessmentCode && isDesktop) {
                        const queryParams = new URLSearchParams({
                            courseCode,
                            assessmentCode,
                        }).toString();
        
                        navigate(`/login?${queryParams}`);                
                    } else {
                        navigate(`/${courseCode}/${assessmentCode}`)
                    }
                }
            } catch (error) {
                console.log(error)
            } finally {
                setRequestLifeCycle(false);
            }
        }

        getCustomExamStudent();

    }, [])

    useEffect(() => {
        const fetchZip = async () => {
            try {
                setRequestLifeCycleThree(true);
                const res = await fetch( process.env.REACT_APP_SUBMISSION_API_URL  + `/get-submission-zip-student-evaluation/${courseCode}/${assessmentCode}`, {
                    method: "GET",
                    credentials: "include"
                });
                if (res.status === 200) {
                    const blob = await res.blob(); // Get the ZIP as a blob
                    const contents = parseFileStructure(await parseZipFile(blob));
                    setContents(contents);
                    setZipFileExists(true);
                } else if (res.status === 401) {
                    if (courseCode && assessmentCode && isDesktop) {
                        const queryParams = new URLSearchParams({
                            courseCode,
                            assessmentCode,
                        }).toString();
        
                        navigate(`/login?${queryParams}`);
                    } else {
                        window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
                    }
                } else if (res.status === 450 || res.status === 451 || res.status === 452 || res.status === 453 || res.status === 455) {
                    if (isDesktop) {
                        window.electron.setKioskMode(false);
                    }
                    navigate(`/${courseCode}/${assessmentCode}`);
                } else if (res.status === 454) {
                    if (courseCode && assessmentCode && isDesktop) {
                        const queryParams = new URLSearchParams({
                            courseCode,
                            assessmentCode,
                        }).toString();
        
                        navigate(`/login?${queryParams}`);                
                    } else {
                        navigate(`/${courseCode}/${assessmentCode}`)
                    }
                } else {
                    setZipFileExists(false);
                }
            } catch (error) {
                console.log(error);
            } finally {
                setRequestLifeCycleThree(false);
            }
        };
        
        if (zipFileExists) {
            fetchZip();  
        }        

    }, [zipFileExists])

    useEffect(() => {
        const fetchPdf = async () => {
            try {
                setRequestLifeCycleTwo(true);   
                const res = await fetch( process.env.REACT_APP_EVALUATION_API_URL  + `/get-assessment-pdf-student/${courseCode}/${assessmentCode}`, {
                    method: "GET",
                    credentials: "include"
                });
                const data = await res.json();
                if (res.status === 200) {
                    const [status, blob] = await fetchWithPreSigned(data.url); // Get the ZIP as a blob
                    
                    if (status !== 200) {
                        return;
                    }

                    const pdfUrl = URL.createObjectURL(blob); // Directly create object URL
                    setPdf(pdfUrl)
                    setPdfExists(true);
                } else if (res.status === 401) {
                    if (courseCode && assessmentCode && isDesktop) {
                        const queryParams = new URLSearchParams({
                            courseCode,
                            assessmentCode,
                        }).toString();
        
                        navigate(`/login?${queryParams}`);
                    } else {
                        window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
                    }
                } else if (res.status === 450 || res.status === 451 || res.status === 452 || res.status === 453 || res.status === 455) {
                    if (isDesktop) {
                        window.electron.setKioskMode(false);
                    }
                    navigate(`/${courseCode}/${assessmentCode}`);
                } else if (res.status === 454) {
                    if (courseCode && assessmentCode && isDesktop) {
                        const queryParams = new URLSearchParams({
                            courseCode,
                            assessmentCode,
                        }).toString();
        
                        navigate(`/login?${queryParams}`);                
                    } else {
                        navigate(`/${courseCode}/${assessmentCode}`)
                    }
                } else {
                    setRequestLifeCycleTwo(false);
                }

            } catch (error) { 
                console.log(error);
            } finally {
                setRequestLifeCycleTwo(false);
            }
        };

        if (pdfExists) {
            fetchPdf();
        }

        return () => {
            if (pdf) {
                URL.revokeObjectURL(pdf);
            }
        }

    }, [pdfExists])    

    function updateQuestionsToSave(questionsToSave) {
        setQuestionsToSave(questionsToSave);
    }

    function updateCurrentViewer(viewer) {
        setCurrentViewer(viewer);
    }

    function updateContentsNeedToBeSaved(bool) {
        setContentsNeedToBeSaved(bool);
    }

    function updateContents(contents) {
        updateContentsNeedToBeSaved(true);
        setContents(contents);
    }

    function updateAttempt(newAttempt) {
        setAttempt(newAttempt);
    }

    function updateStatus(newStatus) {
        setStatus(newStatus);
    }

    function updateFetchError(newFetchError) {
        setFetchError(newFetchError);
    }

    const viewerToReturn = () => {
        switch(currentViewer) {
            case TypesOfLocationEnum.CUSTOM_EXAM: {
                return <CustomExamExtendedWrapperView fetchError={fetchError} coupledProgrammingQuestions={coupledProgrammingQuestions} updateAttempt={updateAttempt} status={status} allowedAttemptsProgramming={allowedAttemptsProgramming} attempt={attempt} error={error} testsConfigDict={testsConfigDict} questionsToSave={questionsToSave} requestLifeCycleTwo={requestLifeCycleThree || requestLifeCycleFour} isDarkTheme={isDarkTheme} updateIsDarkTheme={updateIsDarkTheme} useSyntaxHighlighting={useSyntaxHighlighting} updateContents={updateContents} requestLifeCycle={requestLifeCycle} questions={questions} updateQuestionsToSave={updateQuestionsToSave} contents={contents}/>
            }
            case TypesOfLocationEnum.PDF: {
                return <PdfTakeEval currentViewer={TypesOfLocationEnum.PDF} requestLifeCycle={requestLifeCycleTwo} pdf={pdf}/>
            }
            case TypesOfLocationEnum.SPLIT_SCREEN: {
                return <SplitScreenView fetchError={fetchError} coupledProgrammingQuestions={coupledProgrammingQuestions} updateAttempt={updateAttempt} status={status} allowedAttemptsProgramming={allowedAttemptsProgramming} attempt={attempt} error={error} testsConfigDict={testsConfigDict} questionsToSave={questionsToSave} isDarkTheme={isDarkTheme} updateIsDarkTheme={updateIsDarkTheme} useSyntaxHighlighting={useSyntaxHighlighting} updateContents={updateContents} requestLifeCycle={requestLifeCycle} questions={questions} updateQuestionsToSave={updateQuestionsToSave} contents={contents} requestLifeCycleTwo={requestLifeCycleTwo} pdf={pdf}/>
            }
            default: {
                return <></>
            }
        }
    }

    useEffect(() => {
        function clearClipboard() {
            navigator.clipboard.writeText('').then(() => {
            }).catch(err => {
              console.error('Failed to clear clipboard: ', err);
            });
          }

        if (isDesktop) {
            clearClipboard()
        }
    }, [])

    useEffect(() => {
        const fetchTests = async () => {
            try {
                setRequestLifeCycleFour(true);
                const response = await fetch( process.env.REACT_APP_SUBMISSION_API_URL  + `/get-tests-student/${courseCode}/${assessmentCode}`, {
                    method: "GET",
                    credentials: "include"
                });
                const data = await response.json();
                if (response.status === 200) {
                    if (data.OK) {
                        const testsConfigArray = JSON.parse(data.testsConfig)
                        setTestsConfigDict(testsConfigArray.tests.reduce((acc, curr) => {
                            acc[curr.id] = curr;
                            return acc;
                        }, {}));
                    } else {
                        setError(data.error)
                    }
                    setAttempt(data.attempt)
                    setStatus(data.status)
                } else if (response.status === 401) {
                    if (courseCode && assessmentCode && isDesktop) {
                        const queryParams = new URLSearchParams({
                            courseCode,
                            assessmentCode,
                        }).toString();
        
                        navigate(`/login?${queryParams}`);
                    } else {
                        window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
                    }
                } else {
                    setError(data.detail)
                }
            } catch (error) {
                setError(error.message)
                console.log(error);
            } finally {
                setRequestLifeCycleFour(false)
            }
        };
        
        fetchTests();
    }, [])

    useEffect(() => {
        if (questions && questions.length > 0) {
            const programmingQuestionIndices = questions
                .map((question, index) => 
                    question.questionType === TypesOfQuestionsEnum.PROGRAMMING ? index + 1 : undefined
                )
                .filter(index => index !== undefined);
            
            setCoupledProgrammingQuestions(programmingQuestionIndices);
        }

    }, [questions]);    
    
    return (
        <>
            <NavTakeEvaluation updateFetchError={updateFetchError} updateStatus={updateStatus} updateAttempt={updateAttempt} coupledProgrammingQuestions={coupledProgrammingQuestions} attempt={attempt} allowedAttemptsProgramming={allowedAttemptsProgramming} inKioskParent={inKioskParent} times={times} contentsNeedToBeSaved={contentsNeedToBeSaved} updateContentsNeedToBeSaved={updateContentsNeedToBeSaved} contents={contents} questionsToSave={questionsToSave} updateQuestionsToSave={updateQuestionsToSave}/>
            <div className="wrapper-for-current-viewer">
                {
                    viewerToReturn()
                }
            </div>
            <LocationsForExam isProgramming={isProgramming} pdfExists={pdfExists} updateCurrentViewer={updateCurrentViewer} currentViewer={currentViewer}/>
        </>
    );
}

export default TakeEvaluation;