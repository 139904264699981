import React, { useEffect, useState } from "react";
import Plus from "./plus";
import useWindowWidth from "./useWindowWidth";
import AddACourseForm from "./addACourseForm";

const AddCourse = (props) => {
    let paddingLeft = "20px";
    const [delay, setDelay] = useState(false);
    const width = useWindowWidth(495);
    const [adding, setAdding] = useState(false);

    if (width && props.open) {
        paddingLeft = "25px";
    }

    useEffect(() => {
        let timeout;
    
        if (!props.open) {
            timeout = setTimeout(() => {
                setDelay(false);
            }, 700);
        } else {
            setDelay(true);
        }
    
        return () => clearTimeout(timeout);
    }, [props.open]);

    const updateAdding = (bool) => {
        setAdding(bool)
    }

    return (
        <>
            <div className={`add-course ${props.open ? width ? "open" : "closed" : "closed"} basic-container`} style={{ "paddingLeft": paddingLeft }} onClick={ () => {updateAdding(true)} }>
                <Plus bool={false}/>
                { props.open ? (width ? "" : "Add Course") : (delay ? (width ? "" : "Add Course") : "Add Course") }
            </div>
            { adding ? <AddACourseForm updateAdding={updateAdding}/> : <></> }
        </>
    );
}

export default AddCourse;