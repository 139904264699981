import React, { useState, useEffect } from "react";
import CircleWithTextView from "./circleWithTextView";
import MarkdownWithLaTeX from "./markDownWithLatex";
import CircleWithTextViewGradingPage from "./circleWithTextViewGradingPage";
import Explanation from "./explanation";
import { useLocation, useNavigate } from "react-router-dom";

const MultipleChoiceView = ({ questionsToSave, isDarkTheme, explanation, useExplanation, questionNumber, question, points, options, grading, updateQuestionsToSave, uuid, answerIndexPassed, pointsEarned, studentAnswer, answerIndexAnswer }) => {
    const [answerIndex, setAnswerIndex] = useState(grading ? answerIndexPassed : studentAnswer);
    const [initCall, setInitCall] = useState(true);
    const navigate = useNavigate();
    const locationURL = useLocation();

    function updateIndex(newIndex) {
        if (newIndex === answerIndex) {
            setAnswerIndex(-1)
        } else {
            setAnswerIndex(newIndex);
        }
    }

    useEffect(() => {
        // init checking
        if (initCall) {
            setInitCall(false);
            return;
        }
        
        // construct new body
        const body = {
            answerIndex: answerIndex
        };

        // updateQuestionsToSave
        updateQuestionsToSave((prev) => ({
        ...prev,
        [uuid]: body
        }))

    }, [answerIndex])

    const handleStateCalculation = (answerState) => {
        // check if answers match or check if they match the possible answerState
        if (studentAnswer === answerIndexAnswer && (answerIndexAnswer === answerState)) {
            return 1;
        } 
        // check if the answers dont't match and the correct answer state is a match
        else if (studentAnswer !== answerIndexAnswer && (studentAnswer === answerState)) {
            return 0;
        }
        // if answer but student incorrect
        else if (studentAnswer !== answerIndexAnswer && (answerIndexAnswer === answerState)) {
            return 2;
        }
        // if both fail, we have a blank answer
        else {
            return -1;
        }
    }

    useEffect(() => {
        const questionData = questionsToSave ? questionsToSave[uuid] : null

        if (questionData && "answerIndex" in questionData) {
            setAnswerIndex(questionData.answerIndex);
        }

    }, [questionsToSave])

    const setNewParams = () => {        
        const searchParams = new URLSearchParams(locationURL.search);
        searchParams.set("uuid", uuid);
        navigate(`?${searchParams.toString()}`);        
    }

    return (
        <>
            <div className="true-false-question-view" id={uuid} onClick={setNewParams}>
                <div className="question-number">
                    <>
                        Q{ questionNumber }
                    </>
                    <div className="pts">
                        { (grading ? Number(pointsEarned).toFixed(2) + " / " : "") + Number(points).toFixed(2) } pts
                    </div>
                </div>
                <div className="true-false-top">
                    <MarkdownWithLaTeX content={question} isDarkTheme={isDarkTheme}/>
                </div>
                <div className="select-all-that-apply-array">
                    {
                        options && options.length > 0 

                        ?

                        options.map((option) => (
                            
                            grading 

                            ?

                            <CircleWithTextViewGradingPage key={option.index} state={handleStateCalculation(option.index)} text={option.option}/>

                            :

                            <CircleWithTextView key={option.index} correct={answerIndex === option.index} index={option.index} text={option.option} updateIndex={updateIndex}/>
                        ))

                        :

                        <>
                        </>

                    }
                </div>
                <Explanation grading={grading} useExplanation={useExplanation} explanation={explanation}/>
            </div>
        </>
    );
}

export default MultipleChoiceView;