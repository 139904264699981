import React, { useEffect, useState } from "react";
import SaveChanges from "./saveChanges";
import { useParams, useNavigate } from "react-router-dom";
import Info from "./info";
import Loading from "./loading";
import zipUpContents from "./zipUpContents";

const SubmitCustomExamForm = ({ autoSubmit, updateContentsNeedToBeSaved, updateQuestionsToSave, contents, contentsNeedToBeSaved, questionsToSave, updateIsAttemptingToSubmit }) => {
    const [text, setText] = useState("Are you sure you want submit this evaluation?");
    const [requestLifeCycle, setRequestLifeCycle] = useState(false);
    const { courseCode, assessmentCode } = useParams();
    const navigate = useNavigate();

    const isDesktop = process.env.REACT_APP_IS_DESKTOP === "true"

    const resetForm = () => {
        updateIsAttemptingToSubmit(false);
    }

    const saveAnswers = async () => {
        // if not answers to save
        if (Object.keys(questionsToSave).length === 0 && !contentsNeedToBeSaved) {
            return true;
        }

        // create formdata
        const formData = new FormData();
        formData.append('courseCode', courseCode);
        formData.append('assessmentCode', assessmentCode);
        formData.append('answers', JSON.stringify(Object.keys(questionsToSave).map(key => ({...questionsToSave[key], uuid: key}))));

        // check if we have a zip file
        if (contentsNeedToBeSaved) {
            formData.append('contents', await zipUpContents(contents));
        }

        const url = process.env.REACT_APP_SUBMISSION_API_URL  + "/temporary-save-answers"
        const urlOptions = {
            method: "POST",
            credentials: "include",
            body: formData
        }

        try {
            const response = await fetch(url, urlOptions);
            if (response.status === 200) {
                return true;
            } else if (response.status === 401) {
                if (courseCode && assessmentCode && isDesktop) {
                    const queryParams = new URLSearchParams({
                        courseCode,
                        assessmentCode,
                    }).toString();
    
                    navigate(`/login?${queryParams}`);
                } else {
                    window.location.href = process.env.REACT_APP_401_REDIRECT_URL
                }
            } else if (response.status === 450 || response.status === 451 || response.status === 452 || response.status === 453 || response.status === 455) {
                if (isDesktop) {
                    window.electron.setKioskMode(false);
                }
                navigate(`/${courseCode}/${assessmentCode}`);
            } else if (response.status === 454) {
                if (courseCode && assessmentCode && isDesktop) {
                    const queryParams = new URLSearchParams({
                        courseCode,
                        assessmentCode,
                    }).toString();
    
                    navigate(`/login?${queryParams}`);                
                } else {
                    navigate(`/${courseCode}/${assessmentCode}`)
                }
            }
            return false;
        } catch (error) {
            console.log(error)
            return false;
        }
    }

    const submitForm = async (event) => {
        if (event && typeof event.preventDefault === 'function') {
            event.preventDefault();
        }

        if (requestLifeCycle) {
            return;
        }

        setRequestLifeCycle(true);

        const didSave = await saveAnswers();

        // first save the asnwers
        if (didSave) {
            updateQuestionsToSave({});
            updateContentsNeedToBeSaved(false);
        } else {
            setText("Answer Save Failed");
            return;
        }    

        const url = process.env.REACT_APP_START_AUTOGRADER_API_URL
        const urlOptions = {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                courseCode: courseCode,
                assessmentCode: assessmentCode,
                email: "placeholder"
            })
        }
        
        try {
            const response = await fetch(url, urlOptions);            
            if (response.status === 200) {
                try {
                    navigator.clipboard.writeText("")
                } catch (error) {
                    console.log(error)
                }
                
                if (isDesktop) {
                    window.electron.setKioskMode(false);
                }
                
                navigate(`/${courseCode}/${assessmentCode}`);

            } else if (response.status === 401) {
                setText("Failed");
                if (courseCode && assessmentCode && isDesktop) {
                    const queryParams = new URLSearchParams({
                        courseCode,
                        assessmentCode,
                    }).toString();
    
                    navigate(`/login?${queryParams}`);
                } else {
                    window.location.href = process.env.REACT_APP_401_REDIRECT_URL
                }
            } else if (response.status === 450 || response.status === 451 || response.status === 452 || response.status === 453 || response.status === 455) {
                setText("Failed");
                if (isDesktop) {
                    window.electron.setKioskMode(false);
                }
                navigate(`/${courseCode}/${assessmentCode}`);
            } else if (response.status === 400) {
                setText("Autograder failed. Wait 3s for redirection");
                setTimeout(() => {
                    try {
                        navigator.clipboard.writeText("")
                    } catch (error) {
                        console.log(error)
                    }

                    if (isDesktop) {
                        window.electron.setKioskMode(false);
                    }
                    navigate(`/${courseCode}/${assessmentCode}`)
                }, 3000)
            } else if (response.status === 454) {
                if (courseCode && assessmentCode && isDesktop) {
                    const queryParams = new URLSearchParams({
                        courseCode,
                        assessmentCode,
                    }).toString();
    
                    navigate(`/login?${queryParams}`);                
                } else {
                    navigate(`/${courseCode}/${assessmentCode}`)
                }
            } else {
                setText("Failed");
            }
        } catch (error) {
            setText("Failed");
            console.log(error)
        } finally {
            try {
                navigator.clipboard.writeText("")
            } catch (error) {
                console.log(error)
            }

            setRequestLifeCycle(false);
        }
    }

    useEffect(() => {
        if (autoSubmit) {
            submitForm();
        }
    }, [autoSubmit])

    return (
        <>
            <div className="black-back" onClick={ () => {updateIsAttemptingToSubmit(false)} }>

            </div>
            <div className="add-a-course-form-container">
                <div className="add-a-course-form-content-container">
                    <form className="form-width">
                        <div className="add-course-title">
                            Submit Evaluation
                        </div>
                        <div className="account-settings-content">
                            <div className="required-container">
                                All Fields Required
                            </div>   
                            <div className="banner">
                                {
                                    requestLifeCycle 

                                    ?
                                    
                                    <>
                                        <Loading />
                                    </>

                                    :

                                    <div className="banner-info">
                                        <Info />
                                        { text }
                                    </div>

                                }
                            </div>
                            <SaveChanges left="Cancel" right={{ small : "Submit", large : "Submit" }} resetForm={resetForm} submitForm={submitForm}/> 
                        </div>
                    </form> 
                </div>
            </div>
        </>
    );
}

export default SubmitCustomExamForm;