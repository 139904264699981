import React from "react";
import PDFIcon from "./pdfIcon"
import PDF from "./pdf";
import Loading from "./loading";
import useWindowWidth from "./useWindowWidth";
import TypesOfLocationEnum from "./typesOfLocationEnum";

const PdfTakeEval = ({ pdf, requestLifeCycle, currentViewer }) => {

    return (
        <>
            <div className={`pdf-content-container ${ currentViewer === TypesOfLocationEnum.PDF ? "custom-exam" : "special-height"}`} style={{ minHeight: "600px"}}>
                {
                    requestLifeCycle

                    ?

                    <div className="loading-zip-container">
                        <Loading />
                    </div>

                    :

                    <>
                        {
                            pdf === "" 
        
                            ?
        
                            <div className="loading-zip-container">
                                <PDFIcon />
                            </div>
        
                            :
        
                            <>
                                <PDF pdf={pdf}/>
                            </>
                        }
                    </>
                }
            </div>
        </>
    );
}

export default PdfTakeEval;