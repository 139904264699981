import React, { Suspense, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import SemesterDashboard from "./components/semesterDashboard";
import CourseDashboard from "./components/courseDashboard";
import AccountSettings from "./components/accountSettings";
import AssessmentPage from "./components/assessmentPage";
import TakeEvaluation from "./components/takeEvaluation";

function App() {
    // Fetch user role
    useEffect(() => {
        const fetchRole = async () => {
            try {
                const response = await fetch( process.env.REACT_APP_USER_API_URL + "/get-role", {
                    method: "GET",
                    credentials: "include"
                });
                const data = await response.json();
                if (data.role === "professor") {
                    if (process.env.REACT_APP_IS_DESKTOP !== "true") {
                        window.location.href = process.env.REACT_APP_403_REDIRECT_URL; // redirect to professor page
                    }
                }
            } catch (error) {
                console.log(error);
            }
        };

        fetchRole();
    }, []);

    // Check for desktop environment
    const isDesktop = process.env.REACT_APP_IS_DESKTOP === 'true';

    const LoginConditional = isDesktop
        ? React.lazy(() => import('./components/login'))
        : null;

    const PrivacyPolicyConditional = !isDesktop
        ? React.lazy(() => import('./components/privacyPolicy'))
        : null;
    
    const TermsAndConditionsConditional = !isDesktop
        ? React.lazy(() => import('./components/termsAndConditions'))
        : null;

    return (
        <Router>
            <Suspense fallback={<div>Loading...</div>}>
                <Routes>
                    <Route path="/" element={<SemesterDashboard />} />
                    <Route path="/:courseCode" element={<CourseDashboard />} />
                    <Route path="/:courseCode/:assessmentCode" element={<AssessmentPage />} />
                    <Route path="/:courseCode/:assessmentCode/:email" element={<AssessmentPage />} />
                    {isDesktop && <Route path="/login" element={<LoginConditional />} />}
                    <Route path="/:courseCode/:assessmentCode/take-evaluation" element={<TakeEvaluation />} />
                    <Route path="/account-settings" element={<AccountSettings />} />
                    {!isDesktop && <Route path="/privacy-policy" element={ <PrivacyPolicyConditional /> } />} 
                    {!isDesktop && <Route path="/terms-and-conditions" element={ <TermsAndConditionsConditional /> } />}
                </Routes>
            </Suspense>
        </Router>
    );
}

export default App;