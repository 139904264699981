import React, { useState } from "react";
import EyeOpen from "./eyeOpen";
import EyeClosed from "./eyeClosed";

const AccountInput = (props) => {
    const [seePassword, setSeePassword] = useState(true);

    function updateSeePassword(bool) {
        setSeePassword(bool);
    }

    const handleChange = (e) => {
        props.updateValue(e.target.value);
    }

    return (
        <>
            <div className="input-field">
                <div className="top-input">
                    { props.top }
                    {
                        props.required

                        ?
                            <div className="required">
                                *
                            </div>
                        :
                            <></>
                            
                    }
                </div>
                <input className="bottom-input" type={ props.top.includes("Password") ? seePassword ? "password" : "text" : "text" } value={ props.value } onChange={ handleChange } required={ props.required } autoComplete={ props.autoComplete } placeholder={ props.top === "Add Email Address" ? "Your-Example@Provider.com"  : "" } />
                {
                    props.top.includes("Password")

                    ?

                    seePassword

                    ?
                        <>
                            <EyeOpen updateSeePassword={updateSeePassword}/>
                        </>
                    :

                        <>
                            <EyeClosed updateSeePassword={updateSeePassword}/>
                        </>

                    :

                    <></>

                }
            </div>
        </>
    );
}

export default AccountInput