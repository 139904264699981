const EvaluationStatusEnum = {
    'OUTSIDE_TIME_RANGE': 'OUTSIDE_TIME_RANGE',
    'OUT_OF_ATTEMPTS': 'OUT_OF_ATTEMPTS',
    'NOT_ENTERED_ENTRY_CODE': 'ENTERED_ENTRY_CODE',
    'BEGIN': 'BEGIN',
    'NEW_ATTEMPT': 'NEW_ATTEMPT',
    'ERROR': 'ERROR',
    'CONTINUE': 'CONTINUE'
}

export default EvaluationStatusEnum;