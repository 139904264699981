import fetchWithPreSigned from "./fetchWithPreSigned";

const fetchSubmissionPdf = async (url) => {
    try {
        const res = await fetch( url, {
            method: "GET",
            credentials: "include"
        });
        const data = await res.json()
        if (res.status === 200) {
            const [status, blob] = await fetchWithPreSigned(data.url); // Get the ZIP as a blob
            
            if (status !== 200) {
                return [false, "NO_PDF"];
            }
            
            // Return a promise that resolves once FileReader has finished reading the file
            return new Promise((resolve) => {
                const pdfUrl = URL.createObjectURL(blob); // Directly create object URL
                resolve([true, pdfUrl]);
            });
        } else {
            return [false, "NO_PDF"]
        }

    } catch (error) {
        console.log(error);
        return [false, "NO_PDF"];
    }
};

export default fetchSubmissionPdf;