import React, { useState, useEffect } from "react";
import Navigation from "./navigation";
import { useParams, useNavigate } from "react-router-dom"; // need for api call later
import DownArrow from "./downArrow";
import Assessment from "./assessment";
import Materials from "./materials";
import useScrollToTop from "./useScrollToTop";
import NavInfoCourse from "./navInfoCourse";
import useWindowWidth from "./useWindowWidth";
import Loading from "./loading";
import handleStatusDate from "./handleStatusDate";
import UnenrollForm from "./unenrollForm";
import moment from "moment-timezone";
import Search from "./search";
import useNavigateToLoginPageEnv from "./useNavigateToLoginPageEnv";

const CourseDashboard = () => {
    const navigateToLoginPageEnv = useNavigateToLoginPageEnv();
    useScrollToTop();
    const navigate = useNavigate();
    const [requestLifeCycle, setRequestLifeCycle] = useState(false);
    const { courseCode } = useParams(); // need for api call later
    const width = useWindowWidth(800);
    const widthTwo = useWindowWidth(540);
    const widthThree = useWindowWidth(340);
    const widthFour = useWindowWidth(550);
    const [unenroll, setUnenroll] = useState(false);
    const [course, setCourse] = useState({});
    const [searchName, setSearchName] = useState("");
    const [nameFlip, setNameFlip] = useState(false);
    const [statusFlip, setStatusFlip] = useState(false);
    const [endTimeFlip, setEndTimeFlip] = useState(false);

    useEffect(() => {
        const fetchCourse = async () => {
            try {
                setRequestLifeCycle(true);
                const res = await fetch( process.env.REACT_APP_COURSE_API_URL  + `/get-course-student/${courseCode}`, {
                    method: "GET",
                    credentials: "include"
                });
                const data = await res.json();
                if (res.status === 200) {
                    setCourse(data)
                    data.assessments.map((assessment) => {
                        if ('submission' in assessment && Object.keys(assessment.submission).length > 0) {
                            assessment.status = assessment.submission.endedExam ? "Submitted" : "Unfinished"
                        } else {                            
                            assessment.status = handleStatusDate(assessment.start, assessment.end)
                        }
                        return assessment;
                    })

                    setAssessmentsArr(data.assessments.sort((a,b) => {
                        const now = moment();
                        const startA = moment.utc(a.start)
                        const endA = moment.utc(a.end)
                        const startB = moment.utc(b.start)
                        const endB = moment.utc(b.end)
                        if (endA.isAfter(now) && endB.isAfter(now)) { 
                            // if both are in progress
                            if (startA.isBefore(now) && startB.isBefore(now)) {
                                return 0;
                            }
                            // if a is in progress and b is not in progress 
                            else if (startA.isBefore(now)) {
                                return -1;
                            }
                            // if b is in progress and a is not in progress
                            else if (startB.isBefore(now)) {
                                return 1;
                            } 
                            // if all are upcoming, sort by end time
                            return endA.isBefore(endB) ? -1 : 1 ;
                        } else if (endA.isAfter(now)) {
                            // Only endA is after now, so it should come first
                            return -1;
                        } else if (endB.isAfter(now)) {
                            // Only endB is after now, so it should come first
                            return 1;
                        } else {
                            // Both are before now, so sort in reverse chronological order (later dates first)
                            return endA.isAfter(endB) ? -1 : 1;
                        }
                    }));
                    setInstructorsArr(data.instructors);
                } else if (res.status === 401) {
                    navigateToLoginPageEnv();
                }
        
            } catch (error) {
                console.log(error);
            } finally {
                setRequestLifeCycle(false);
            }
        }

        fetchCourse();
    }, [])

    const [assessmentsArr, setAssessmentsArr] = useState([]);
    const [instructorsArr, setInstructorsArr] = useState([]);

    const changeStatusSort = () => {
        let arr = [];

        if (assessmentsArr && assessmentsArr.length > 1) {
            for (let i = 0; i < assessmentsArr.length; i++) {
                const assessment = assessmentsArr[i];

                if ('submission' in assessment && Object.keys(assessment.submission).length > 0) {
                    assessment.status = assessment.submission.endedExam ? "Submitted" : "Unfinished"
                    
                    arr = arr.includes(assessment.status) ? arr : [...arr, assessment.status]

                } else {
                    const status = handleStatusDate(assessment.start, assessment.end)
                    arr = arr.includes(status) ? arr : [...arr, status]
                }
            }
        }

        setAssessmentsArr([...assessmentsArr].sort((a, b) => {
            if (arr && arr.length > 1) {
                return arr.indexOf(b.status) - arr.indexOf(a.status);
            }            
        }))
        
        setStatusFlip(prev => !prev);
        setNameFlip(false);
        setEndTimeFlip(false);
    }

    const changeNameSort = () => {
        let sortOrder = null;

        for (let i = 0; i < assessmentsArr?.length; i++) {
            let l = i - 1;

            if (i > 0 && assessmentsArr[l].name.localeCompare(assessmentsArr[i].name) > 0) {
                sortOrder = true;
                break;
            }
        }

        setAssessmentsArr([...assessmentsArr].sort((a, b) => {
            if (sortOrder) {
                return a.name.localeCompare(b.name);  // Correctly compare strings
            } else {
                return b.name.localeCompare(a.name);  // Correctly compare strings in reverse order
            }
        }));

        setNameFlip(prev => !prev);
        setStatusFlip(false);
        setEndTimeFlip(false);
    }; 

    function updateUnenroll(unenroll) {
        setUnenroll(unenroll);
    }

    function updateSearchName(e) {
        setSearchName(e.target.value);
    }

    const findStart = (assessment) => {
        if ('extraTime' in assessment  && assessment.startTime) {
            return assessment.extraTime.startTime
        } else {
            return assessment.start
        }
    }

    const findEnd = (assessment) => {
        if ('extraTime' in assessment && assessment.endTime) {
            return assessment.extraTime.endTime
        } else {
            return assessment.end
        }
    }

    const sortEndDate = () => {
        let sortOrder = null;

        for (let i = 0; i < assessmentsArr?.length; i++) {
            let l = i - 1;

            if (i > 0 && moment.utc(assessmentsArr[l].end).isAfter(moment.utc(assessmentsArr[i].end))) {
                sortOrder = true;
                break;
            }
        }
        
        setAssessmentsArr([...assessmentsArr].sort((a, b) => {
            if (sortOrder) {
                return moment.utc(a.end).isAfter(moment.utc(b.end)) ? 1 : -1;  // Correctly compare strings
            } else {
                return moment.utc(b.end).isAfter(moment.utc(a.end)) ? 1 : -1;  // Correctly compare strings in reverse order
            }
        }));
        
        setEndTimeFlip(prev => !prev);
        setNameFlip(false);
        setStatusFlip(false);
    };

    return (
        <>
            <Navigation boolThree={false} boolTwo={false} bool={false} info={<NavInfoCourse updateUnenroll={updateUnenroll} name={course.name} description={course.description} instructors={instructorsArr}/>}/>
            <div className="courses-container">
                <div className="header">
                    <div className="single-course-container">
                        <div className="your-course">
                            <div className="course-metadata" style={{ flexDirection: width ? "column" : "row", alignItems:  width ? "flex-start" : "center", position: "relative" }}> 
                                {
                                    course && Object.keys(course).length > 0

                                    ?

                                    <>
                                        <div className={`course-name ${width ? (widthFour ? "overflow-name-small-small" : "overflow-name-small") : "overflow-name"}`}>
                                            { course.name }
                                        </div>
                                        { width ? <></> : <div className="bar"></div> }
                                        <div className="font two overflow-code">
                                            <div>
                                                { course.term + " " + course.year }
                                            </div>
                                        </div>
                                        <div className={ widthFour ? "search-holder-bottom" : "search-holder"} style={{width: "180px"}}>
                                            <Search searchName={searchName} updateSearchName={updateSearchName}/>
                                        </div>
                                    </>
                                

                                    :

                                    <>
                                    </>

                                }
                            </div>
                            <div className="course-metadata-2" style={{ paddingBottom: "10px" }}> 
                                <div className={ widthTwo ? "arrow-container-1-2 no-under" : "arrow-container-1 no-under" }>
                                    <div className={ widthTwo ? "arrow-container-1-2 no-under" : "arrow-container-1 no-under" } style={{ width: "fit-content", cursor: "pointer", userSelect: "none" }} onClick={ widthTwo ? changeStatusSort : changeNameSort }>
                                        <DownArrow flip={nameFlip}/>
                                        <span> Name </span>
                                        <Materials />
                                    </div>
                                </div>
                                {  
                                
                                widthTwo

                                ?
                                    <></>
                                :

                                    <div className={ width ? "arrow-container-2-2" : "arrow-container-2" }>
                                        <div className="status-container" onClick={ changeStatusSort } style={{ userSelect: "none" }}>
                                            <DownArrow flip={statusFlip}/>
                                            Status
                                        </div>
                                    </div> 

                                }
                                <div className={ width ? widthTwo ? "arrow-container-4-2 fifty" : "arrow-container-4-2" : "arrow-container-4" } style={{ userSelect: "none" }}>
                                    <div className="status-container" onClick={ sortEndDate } style={{ userSelect: "none" }}>
                                        <DownArrow flip={endTimeFlip}/>
                                        { widthThree ? "Times" : "Schedule" } ({moment.tz(moment.tz.guess()).format('z')})
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="exams-container">
                            {
                                requestLifeCycle 

                                ?

                                <>
                                    <div className="loading-container" style={{ height: "200px" }}>
                                        <Loading />
                                    </div>  
                                </>

                                :

                                <>
                                    {
                                        assessmentsArr && assessmentsArr.length > 0

                                        ?

                                        <>
                                            {
                                                assessmentsArr.map((assessment) => {
                                                    // logic for checking start time to either pass "In Progress", "Submitted", "Upcoming", "Missed"
                                                    // determine the avgCompletionTime by the submissions array
                                                    if (assessment.name.toLowerCase().includes(searchName.toLowerCase())) {
                                                        return <Assessment published={assessment.published} status={assessment.status} key={assessment.assessmentCode} assessmentCode={assessment.assessmentCode} submission={'submission' in assessment ? assessment.submission : "" } endedExam={'submission' in assessment ? assessment.submission.endedExam : false} widthTwo={widthTwo} width={width} extraTime={'extraTime' in assessment ? assessment.extraTime.extraTime : "N/A"} name={assessment.name} start={findStart(assessment)} end={findEnd(assessment)}/>
                                                    }                                            
                                                })
                                            }
                                        </>

                                        :

                                        <>
                                            <div className="no-assignments-container">
                                                <div className="no-assignments"> 
                                                    There are no evaluations currently
                                                    <div className="save-changes-2" onClick={() => { navigate("/"); }}>
                                                        Back To Courses
                                                    </div>
                                                </div>
                                            </div>
                                        </>

                                    }
                                </>                            

                            }
                        </div>
                    </div>
                </div>
            </div>
            { unenroll ? <UnenrollForm updateUnenroll={updateUnenroll}/> : <></> }
        </>
    );
}

export default CourseDashboard;