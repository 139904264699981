import React from "react";
import MarkdownWithLaTeX from "./markDownWithLatex";
import { useLocation, useNavigate } from "react-router-dom";

const ContextBlockView = ({ isDarkTheme, questionNumber, question, points, uuid }) => {
    const navigate = useNavigate();
    const locationURL = useLocation();

    const setNewParams = () => {        
        const searchParams = new URLSearchParams(locationURL.search);
        searchParams.set("uuid", uuid);
        navigate(`?${searchParams.toString()}`);        
    }

    return (
        <>
            <div className="true-false-question-view" id={uuid} onClick={setNewParams}>
                <div className="question-number">
                    <>
                        C{ questionNumber }
                    </>
                    <div className="pts">
                        { Number(points).toFixed(2) } pts
                    </div>
                </div>
                <div className="true-false-top">
                    <MarkdownWithLaTeX content={question} isDarkTheme={isDarkTheme}/>
                </div>
            </div>
        </>
    );
}

export default ContextBlockView;