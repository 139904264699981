import React from "react";
import AddCourse from "./addCourse";
import Person from "./person";
import LogoFill from "./logoFill";
import Evaluation from "./evaluation";

const Nav = (props) => {
    return (
        <>
            <div className="nav-container">
                <div className="basic-container">
                    <LogoFill open={props.open}/>
                    <Person open={props.open}/>
                </div>
                { props.bool && <AddCourse open={props.open}/> }
                {
                    props.boolTwo

                    ?

                    <>
                        <Evaluation />
                    </>

                    :

                    <>
                    </>

                }
            </div>

        </>
    );
}

export default Nav;