import React from "react";
import LocationSettingsItem from "./locationSettingsItem";
import CodeSlash from "./codeSlash";
import PdfFillDoc from "./pdfFillDoc";
import FilePlay from "./filePlay";
import GradingDoc from "./gradingDoc";
import TypesOfLocationEnum from "./typesOfLocationEnum";
import NewProgrammingExamIcon from "./newProgrammingExamIcon";

const LocationSettingsHover = ({ updateCurrentViewer, isProgramming, pdfExists, zipFileExists }) => {

    return (
        <>
            <div className="location-settings-hover">
                <LocationSettingsItem svgOne={<GradingDoc />} updateCurrentViewer={updateCurrentViewer} path={TypesOfLocationEnum.SETTINGS}/>
                {
                    pdfExists

                    ?

                    <LocationSettingsItem svgOne={<PdfFillDoc />} updateCurrentViewer={updateCurrentViewer} path={TypesOfLocationEnum.PDF}/>

                    :

                    <>
                    </>
                }
                {
                    isProgramming 

                    ?

                    <>
                        <LocationSettingsItem svgOne={<NewProgrammingExamIcon />} updateCurrentViewer={updateCurrentViewer} path={TypesOfLocationEnum.CUSTOM_EXAM}/>
                    </>

                    :

                    <>
                    </>

                }
                {
                    isProgramming && zipFileExists

                    ?

                    <>
                        <LocationSettingsItem svgOne={<CodeSlash />} updateCurrentViewer={updateCurrentViewer} path={TypesOfLocationEnum.ZIP}/>
                    </>

                    :

                    <>
                    </>
                }
            </div>
        </>
    );
}

export default LocationSettingsHover;