import React, { useEffect, useState } from "react";
import CourseInput from "./courseInput";
import SaveChanges from "./saveChanges";
import { useParams, useNavigate } from "react-router-dom";
import WhiteLoading from "./whiteLoading";
import Info from "./info";
import useNavigateToLoginPageEnv from "./useNavigateToLoginPageEnv";

const UnenrollForm = (props) => {
    const navigateToLoginPageEnv = useNavigateToLoginPageEnv();
    const navigate = useNavigate();
    const [requestLifeCycle, setRequestLifeCycle] = useState(false);
    const [text, setText] = useState("Type exit message to unenroll from course")
    const { courseCode } = useParams();
    const [remove, setRemove] = useState("");

    function updateRemove(remove) {
        setRemove(remove)
    }

    const resetForm = () => {
        props.updateUnenroll(false);
    }

    const submitForm = async (e) => {
        e.preventDefault();

        if (requestLifeCycle) {
            return;
        }

        if (remove === "Unenroll") {
            const url = process.env.REACT_APP_COURSE_API_URL  + "/unenroll-from-course"

            const urlOptions = {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    courseCode: courseCode
                })
            }

            try {
                setRequestLifeCycle(true);
                const res = await fetch(url, urlOptions);
                if (res.status === 200) {
                    setText("Success");
                    props.updateUnenroll(false);
                    navigate("/");
                } else if (res.status === 401) {
                    setText("Authetication error");
                    navigateToLoginPageEnv();
                } else {
                    setText("An error occurred")
                }
        
            } catch (error) {
                setText("An error occurred")
                console.log(error);
            } finally {
                setRequestLifeCycle(false);
            }
        } else {
            setText("Please type correct exit message");
            return;
        }
    }

    useEffect(() => {
        setText("Type exit message to unenroll from course");
    }, [remove])

    return (
        <>
            <div className="black-back" onClick={ () => {props.updateUnenroll(false)} }>

            </div>
            <div className="add-a-course-form-container instructor" style={{ height: "300px" }}>
                <div className="add-a-course-form-content-container">
                    <form className="form-width">
                        <div className="add-course-title">
                            Leave Course
                        </div>
                        <div className="account-settings-content">
                            <div className="required-container">
                                All Fields Required
                            </div>   
                            <div className="banner">
                                {
                                    requestLifeCycle

                                    ?

                                    <>
                                        <WhiteLoading />
                                    </>

                                    :

                                    <div className="banner-info">
                                        <Info />
                                        { text }
                                    </div>
                                }
                            </div>
                            <CourseInput value={remove} updateValue={updateRemove} top={"Type: Unenroll"} type={"text"} required={true} placeholder={"Unenroll"}/>
                            <SaveChanges left="Cancel" right={{ small : "Unenroll", large : "Unenroll" }} resetForm={resetForm} submitForm={submitForm}/> 
                        </div>
                    </form> 
                </div>
            </div>
        </>
    );
}

export default UnenrollForm;