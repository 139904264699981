import React, { useState, useEffect } from "react";
import useWindowWidth from "./useWindowWidth";
import { useParams } from "react-router-dom";
import SubmitCustomExamForm from "./submitCustomExamForm";
import zipUpContents from "./zipUpContents";
import { useNavigate } from "react-router-dom";
import RunNewTestsProgramming from "./runNewTestsProgramming";

const TopRightCustomExam = ({ updateFetchError, updateStatus, updateAttempt, coupledProgrammingQuestions, allowedAttemptsProgramming, attempt, autoSubmit, contentsNeedToBeSaved, updateContentsNeedToBeSaved, contents, updateQuestionsToSave, questionsToSave }) => {
    const navigate = useNavigate();
    const width = useWindowWidth(650);
    const [text, setText] = useState("Save")
    const [isAttemptingToSubmit, setIsAttemptingToSubmit] = useState(false);
    const { courseCode, assessmentCode } = useParams();

    const isDesktop = process.env.REACT_APP_IS_DESKTOP === "true";

    function updateIsAttemptingToSubmit(is) {
        setIsAttemptingToSubmit(is);
    }

    const saveAnswers = async () => {
        if (text === "Saving") {
            return;
        }

        // create formdata
        const formData = new FormData();
        formData.append('courseCode', courseCode);
        formData.append('assessmentCode', assessmentCode);
        formData.append('answers', JSON.stringify(Object.keys(questionsToSave).map(key => ({...questionsToSave[key], uuid: key}))));

        // check if we have a zip file
        if (contentsNeedToBeSaved) {
            formData.append('contents', await zipUpContents(contents));
        }

        const url = process.env.REACT_APP_SUBMISSION_API_URL  + "/temporary-save-answers"
        const urlOptions = {
            method: "POST",
            credentials: "include",
            body: formData
        }

        try {
            setText("Saving")
            const response = await fetch(url, urlOptions);
            if (response.status === 200) {
                updateQuestionsToSave({});
                updateContentsNeedToBeSaved(false);
                setText("Save");
            } else if (response.status === 401) {
                setText("Failed");
                if (courseCode && assessmentCode && isDesktop) {
                    const queryParams = new URLSearchParams({
                        courseCode,
                        assessmentCode,
                    }).toString();
    
                    navigate(`/login?${queryParams}`);                
                } else {
                    window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
                }
            } else if (response.status === 450 || response.status === 451 || response.status === 452 || response.status === 453 || response.status === 455) {
                if (isDesktop) {
                    window.electron.setKioskMode(false);
                }
                navigate(`/${courseCode}/${assessmentCode}`);
            } else if (response.status === 454) {
                if (courseCode && assessmentCode && isDesktop) {
                    const queryParams = new URLSearchParams({
                        courseCode,
                        assessmentCode,
                    }).toString();
    
                    navigate(`/login?${queryParams}`);                
                } else {
                    navigate(`/${courseCode}/${assessmentCode}`)
                }
            } else {
                setText("Failed");
            }
        } catch (error) {
            setText("Failed");
            console.log(error)
        }
    }

    useEffect(() => {
        if (text === "Failed") {
            const timer = setTimeout(() => {
                setText("Save");
            }, 2000);
    
            // Cleanup the timeout on component unmount or when text changes
            return () => clearTimeout(timer);
        }
    }, [text]);

    useEffect(() => {
        const handleBeforeUnload = (e) => {
            if (Object.keys(questionsToSave).length > 0) {
                // Optionally, you can show a confirmation message if needed
                const confirmationMessage = "You have unsaved changes. Are you sure you want to leave?";
                e.returnValue = confirmationMessage; // Standard for most browsers
                return confirmationMessage; // For some browsers
            }            
        };

        window.addEventListener("beforeunload", handleBeforeUnload);

        // Cleanup the event listener when the component is unmounted
        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, [questionsToSave]); // Ensure this runs when feedback changes

    return (
        <>
            <div className="top-right-custom-wrapper">
                {
                    allowedAttemptsProgramming !== null && allowedAttemptsProgramming !== undefined && allowedAttemptsProgramming !== 0 && (
                        <RunNewTestsProgramming updateFetchError={updateFetchError} updateStatus={updateStatus} width={width} topRight={true} updateAttempt={updateAttempt} coupledProgrammingQuestions={coupledProgrammingQuestions} attempt={attempt ? attempt : 0} allowedAttemptsProgramming={allowedAttemptsProgramming ? allowedAttemptsProgramming : 0}/>
                    )
                }
                {
                    (questionsToSave && Object.keys(questionsToSave).length > 0) || contentsNeedToBeSaved

                    ?

                    <>
                        <div className="top-right-item" onClick={() => {
                            saveAnswers();
                        }} style={{ width: width ? "40px" : "" }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>
                                <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708z"/>
                            </svg>
                            { width ? "" : text}
                        </div>
                    </>

                    :

                    <>
                    </>

                }
                <div className="top-right-item" onClick={() => {
                    updateIsAttemptingToSubmit(true);
                }} style={{ width: width ? "40px" : "" }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                        <path d="M8.5 11.5a.5.5 0 0 1-1 0V7.707L6.354 8.854a.5.5 0 1 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 7.707z"/>
                        <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z"/>
                    </svg>
                    { width ? "" : "Submit"}
                </div>
            </div>
            { isAttemptingToSubmit || autoSubmit ? <SubmitCustomExamForm autoSubmit={autoSubmit} updateContentsNeedToBeSaved={updateContentsNeedToBeSaved} updateQuestionsToSave={updateQuestionsToSave} contents={contents} contentsNeedToBeSaved={contentsNeedToBeSaved} questionsToSave={questionsToSave} updateIsAttemptingToSubmit={updateIsAttemptingToSubmit}/> : <></> }
        </>
    );
}

export default TopRightCustomExam;