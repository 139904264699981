import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ASingleTestGrading from "./aSingleTestGrading";
import Tippy from "@tippyjs/react";
import 'tippy.js/dist/tippy.css';

const RunNewTestsProgramming = ({ updateFetchError, error, updateStatus, updateAttempt, width, coupledProgrammingQuestions, status, attempt, tests, testsConfigDict, allowedAttemptsProgramming, uuid, topRight }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { courseCode, assessmentCode } = useParams();    
    const [requestLifeCycle, setRequestLifeCycle] = useState(false);

    const setNewParams = () => {
        if (!topRight) {
            const searchParams = new URLSearchParams(location.search);
            searchParams.set("uuid", uuid);
            navigate(`?${searchParams.toString()}`);
        }        
    }
 
    const handleClick = async () => {
        if (requestLifeCycle || (attempt >= allowedAttemptsProgramming && allowedAttemptsProgramming !== -1)) {
            return;
        }

        try {
            setRequestLifeCycle(true);  
            updateFetchError(null);
            const url = process.env.REACT_APP_EVALUATION_API_URL + `/test-programming-student/${courseCode}/${assessmentCode}`
            const urlOptions = {
                method: "POST",
                credentials: "include",                
            }
            
            const response = await fetch(url, urlOptions);
            const data = await response.json();
            if (response.status === 200) {
                updateAttempt(prev => prev + 1);
                setNewParams();
                updateStatus('RUNNING');

            } else if (response.status === 401) {
                window.location.href = process.env.REACT_APP_401_REDIRECT_URL
            } else {
                updateFetchError(data.detail);
                updateStatus('COMPLETED');
            }

        } catch (error) {
            console.log(error);
            updateFetchError(error.message);
            updateStatus('COMPLETED');
        } finally {
            setRequestLifeCycle(false);
        }
    }

    const findRemaining = () => {
        const result = allowedAttemptsProgramming - attempt;

        if (result < 0) {
            return 0;
        }

        return result;
    }

    const findStatus = () => {
        if (status === "RUNNING"){
            return " is processing"
        }
    }

    const findQuestions = () => {
    
        let stringToReturn = "";
    
        for (let i = 0; i < coupledProgrammingQuestions.length; i++) {
            if (i > 0) {
                stringToReturn += ", ";
            }
            stringToReturn += coupledProgrammingQuestions[i];
        }
    
        return stringToReturn;
    }

    return (
        <>
            {
                topRight && coupledProgrammingQuestions && coupledProgrammingQuestions.length > 0 && (
                    <div className={`top-right-item ${attempt >= allowedAttemptsProgramming && allowedAttemptsProgramming !== -1 ? "greyed-out-run-tests" : ""}`} onClick={handleClick} style={{ width: width ? "40px" : "fit-content", paddingLeft: width ? "" : "10px", paddingRight: width ? "" : "10px" }}>
                        {
                            width ? 

                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                                <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445"/>
                            </svg>

                            :

                            <>
                                {requestLifeCycle ? "Running" : `Run Tests ${allowedAttemptsProgramming !== -1 ? `(${findRemaining()} left)` : ""}`}
                                {
                                    <Tippy content={<div style={{ fontSize: '12px', textAlign: "center" }}>Running the programming autograder will evaluate the following questions together: {findQuestions()}. Reload periodically for results.</div>} placement={"bottom"}>
                                        <div className="docs-wrapper-icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="white" viewBox="0 0 16 16">
                                                <path stroke="currentColor" strokeWidth=".3" d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>
                                            </svg>
                                        </div>
                                    </Tippy>
                                }
                            </>

                        }                               
                    </div>
                )
            }   
            {
                !topRight && tests && Array.isArray(tests) && tests.length > 0 && (
                    <div className="run-new">
                        {

                            attempt > 0 && (
                                <>
                                    Additional Attempt {attempt}{allowedAttemptsProgramming === -1 ? "" : "/" +  allowedAttemptsProgramming}{findStatus()}:                                    
                                    <div className="true-false-top lambda-results-container">
                                        {                                
                                        
                                            error ? 

                                            <div style={{ color: status === "RUNNING" ? "var(--gray-six)" : "" }}>
                                                {error}
                                            </div>

                                            :

                                            tests.map((id) => {
                                                if (id in testsConfigDict) {
                                                    return <ASingleTestGrading greyedOut={status === "RUNNING"} key={id} {...testsConfigDict[id]}/>
                                                }
                                            })
                                        }
                                    </div>
                                </>
                            )
                        }
                    </div>
                )
            }
        </> 
    );
}

export default RunNewTestsProgramming;