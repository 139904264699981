import React from "react";

const ASingleTestGrading = ({ greyedOut, name, id, totalPoints, index, pointsEarned, output }) => {
    return (
        <>
            <div className="if-output-container">
                <div className="a-single-test" style={{ color: greyedOut ? "var(--gray-six)" : (pointsEarned >= totalPoints ? "var(--correct)" : "var(--incorrect)"), borderRadius: output ? "5px 5px 0px 0px" : "" }}>
                    <div className="left-of-test">
                        { name ? name : "<Missing Name>"}
                    </div>
                    <div className="right-of-test">
                        { (pointsEarned ? Number(pointsEarned).toFixed(2) : "0.00") + "/" + (totalPoints ? Number(totalPoints).toFixed(2) : "0.00") + " pts"}
                    </div>
                </div>
                {
                    output

                    ?

                    <div className="bottom-output" style={{ color: greyedOut ? "var(--gray-six)" : "" }}>
                        { output }
                    </div>

                    :

                    <>
                    </>

                }
            </div>
        </>
    );
}

export default ASingleTestGrading;