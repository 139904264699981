import React from "react";
import CustomExamWrapperView from "./customExamWrapperView";
import { MathJaxContext } from "better-react-mathjax";
import mathJaxConfig from "./mathJaxConfig";
import Loading from "./loading";

const CustomExamViewGradedWrapper = ({ status, endedExam, error, testsConfigDict, requestLifeCycleTwo, pdfUrls, isDarkTheme, updateIsDarkTheme, contents, gradedQuestions, requestLifeCycle }) => {

    return (
        <>
            {

                requestLifeCycle

                ?

                <div className="loading-zip-container">
                    <Loading />
                </div>

                :

                <>
                    <div className="custom-programming-exam-wrapper" style={{ position: "relative" }}>
                        <MathJaxContext config={mathJaxConfig}>
                            <CustomExamWrapperView status={status} endedExam={endedExam} error={error} testsConfigDict={testsConfigDict} requestLifeCycleTwo={requestLifeCycleTwo} pdfUrls={pdfUrls} isDarkTheme={isDarkTheme} updateIsDarkTheme={updateIsDarkTheme} useSyntaxHighlighting={true} contents={contents} questions={gradedQuestions} grading={true}/>
                        </MathJaxContext>
                    </div>
                </>

            }
        </>
    );
}

export default CustomExamViewGradedWrapper;