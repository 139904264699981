import React, { useState, useEffect } from "react";
import Navigation from "./navigation";
import useScrollToTop from "./useScrollToTop";
import NavInfoAssessment from "./navInfoAssessment";
import useWindowWidth from "./useWindowWidth";
import LeftInfoAssessmentPage from "./leftInfoAssessmentPage";
import PdfAssessmentPageContainerWrapper from "./pdfAssessmentPageContainerWrapper";
import ZipFileViewer from "./zipFileViewer";
import LocationSettingsHover from "./locationSettingsHover";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import TypesOfLocationEnum from "./typesOfLocationEnum";
import CustomExamViewGradedWrapper from "./customExamViewGradedWrapper";
import parseZipFile from "./parseZipFile";
import parseFileStructure from "./parseFileStructure";
import useNavigateToLoginPageEnv from "./useNavigateToLoginPageEnv";
import findUuidsOfScanAndGrade from "./findUuidsOfScanAndGrade";
import fetchSubmissionPdf from "./fetchSubmissionPdf";
import findStillProcessing from "./findStillProcessing";

const AssessmentPage = () => {
    const navigateToLoginPageEnv = useNavigateToLoginPageEnv();
    useScrollToTop();
    const width = useWindowWidth(1000);
    const [currentViewer, setCurrentViewer] = useState(TypesOfLocationEnum.SETTINGS)
    const [assessmentInformation, setAssessmentInformation] = useState({});
    const [requestLifeCycle, setRequestLifeCycle] = useState(false);
    const { courseCode, assessmentCode, email } = useParams();
    const [contents, setContents] = useState([]);
    const [contentsForCustomExam, setContentsForCustomExam] = useState({});
    const [blob, setBlob] = useState();
    const [requestLifeCycleTwo, setRequestLifeCycleTwo] = useState(false);
    const [gradedQuestions, setGradedQuestions] = useState([]);
    const [testsConfigDict, setTestsConfigDict] = useState({});
    const [isDarkTheme, setIsDarkTheme] = useState(false);
    const [currentFilename, setCurrentFilename] = useState("");
    const [currentFileData, setCurrentFileData] = useState("");
    const [pdfUrls, setPdfUrls] = useState({})
    const [requestLifeCycleThree, setRequestLifeCycleThree] = useState(false);
    const [error, setError] = useState(null);
    const [status, setStatus] = useState(null);
    const [stillProcessing, setStillProcessing] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const fetchTests = async () => {
            try {
                setRequestLifeCycleThree(true);
                const response = await fetch( process.env.REACT_APP_SUBMISSION_API_URL  + `/get-tests-student/${courseCode}/${assessmentCode}`, {
                    method: "GET",
                    credentials: "include"
                });
                const data = await response.json();
                if (response.status === 200) {
                    if (data.OK) {
                        const testsConfigArray = JSON.parse(data.testsConfig)
                        setTestsConfigDict(testsConfigArray.tests.reduce((acc, curr) => {
                            acc[curr.id] = curr;
                            return acc;
                        }, {}));
                    } else {
                        setError(data.error)
                    }
                    setStatus(data.status);
                } else if (response.status === 401) {
                    navigateToLoginPageEnv();
                } else {
                    setError(data.detail)
                }
            } catch (error) {
                setError(error.message)
                console.log(error);
            } finally {
                setRequestLifeCycleThree(false)
            }
        };
        
        fetchTests();
    }, [])

    useEffect(() => {
        const iterateAndFetchPdfs = async () => {
            // initally set requestLifeCycle
            setPdfUrls({
                requestLifeCycle: true
            })

            // returns a dict where key is uuid of scan and grade and value is null
            const questionsDict = findUuidsOfScanAndGrade(assessmentInformation.submission.gradedQuestions);

            // handle the requests for the pdfs
            const dataPromises = Object.keys(questionsDict).map(async uuid => {
                try {
                    const [isOkay, pdfUrl] = await fetchSubmissionPdf(process.env.REACT_APP_SUBMISSION_API_URL + `/get-question-pdf-student/${courseCode}/${assessmentCode}/${email}/${uuid}`);
                    if (isOkay) {
                        questionsDict[uuid] = pdfUrl;
                    }
                } catch (error) {
                    console.error(error);
                    return null; // Handle errors gracefully
                }
            });

            await Promise.all(dataPromises);
            questionsDict.requestLifeCycle = false 
            setPdfUrls(questionsDict);
        }

        if (assessmentInformation?.submission?.gradedQuestions && assessmentInformation?.submission?.gradedQuestions?.length > 0) {
            iterateAndFetchPdfs()
        }

        return () => {
            Object.keys(pdfUrls).map((uuid) => {
                if (uuid && pdfUrls[uuid]) {
                    URL.revokeObjectURL(pdfUrls[uuid])
                }
            })
        }

    }, [assessmentInformation?.submission?.gradedQuestions])

    function updateCurrentFilename(filename) {
        setCurrentFilename(filename);
    }

    function updateCurrentFileData(data) {
        setCurrentFileData(data);
    }

    useEffect(() => {
        const fetchTheme = async () => {
            try {
                const response = await fetch( process.env.REACT_APP_SUBMISSION_API_URL  + `/get-theme`, {
                    method: "GET",
                    credentials: "include"
                });
                const data = await response.json();
                if (response.status === 200) {
                    setIsDarkTheme(data.isDarkTheme);
                } else if (response.status === 401) {
                    navigateToLoginPageEnv();
                }
            } catch (error) {
                console.log(error);
            }
        };
        
        fetchTheme();
    }, [])

    function updateIsDarkTheme(theme) {
        setIsDarkTheme(theme);
        submitForm(theme);
    }

    const submitForm = async (theme) => {
        try {
            const url = process.env.REACT_APP_SUBMISSION_API_URL  + "/update-theme";
            const urlOptions = {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    isDarkTheme: theme
                })
            }

            const response = await fetch(url, urlOptions);            
            if (response.status === 401) {
                navigateToLoginPageEnv();
            }

        } catch(error) {
            console.log(error);
        }
    }

    useEffect(() => {
        const fetchAssessmentInformation = async () => {
            try {
                setRequestLifeCycle(true);
                const res = await fetch( process.env.REACT_APP_SUBMISSION_API_URL + `/get-submission-settings-student/${courseCode}/${assessmentCode}`, {
                    method: "GET",
                    credentials: "include"
                });
                const data = await res.json();
                if (res.status === 200) {
                    setAssessmentInformation(data);
                    updateAssessmentInformation(data);                                    
                    setGradedQuestions('submission' in data ? data.submission.gradedQuestions : []);                    
                }  else if (res.status === 401) {
                    navigateToLoginPageEnv();
                }
        
            } catch (error) {
                console.log(error);
            } finally {
                setRequestLifeCycle(false);
            }
        }

        fetchAssessmentInformation();
    }, [])

    useEffect(() => {
        const fetchZip = async () => {
            try {
                setRequestLifeCycleTwo(true)
                const res = await fetch( process.env.REACT_APP_SUBMISSION_API_URL  + `/get-submission-zip-student/${courseCode}/${assessmentCode}`, {
                    method: "GET",
                    credentials: "include"
                });
                if (res.status === 200) {
                    const blob = await res.blob(); // Get the ZIP as a blob
                    setBlob(blob);
                    const contents = await parseZipFile(blob);
                    setContents(contents); 
                    const parsedContents = parseFileStructure(contents)
                    setContentsForCustomExam(parsedContents)
                } else if (res.status === 401) { 
                    navigateToLoginPageEnv();
                }
            } catch (error) { 
                console.log(error);
            } finally {
                setRequestLifeCycleTwo(false);
            }
        };
        
        fetchZip(); 

    }, [])

    function updateAssessmentInformation(assessmentInformation) {
        setAssessmentInformation(assessmentInformation);
    }

    function updateCurrentViewer(newViewer) {
        setCurrentViewer(newViewer);
    }

    function currentViewerToReturn() {
        switch(currentViewer) {
            case TypesOfLocationEnum.PDF: {
                return <PdfAssessmentPageContainerWrapper assessmentInformation={assessmentInformation}/>
            }
            case TypesOfLocationEnum.ZIP: {
                return <ZipFileViewer currentFilename={currentFilename} currentFileData={currentFileData} updateCurrentFilename={updateCurrentFilename} updateCurrentFileData={updateCurrentFileData} isDarkTheme={isDarkTheme} updateIsDarkTheme={updateIsDarkTheme} requestLifeCycle={requestLifeCycleTwo} contents={contents} blob={blob}/>
            }
            case TypesOfLocationEnum.CUSTOM_EXAM: {
                return <CustomExamViewGradedWrapper status={status} endedExam={assessmentInformation?.submission?.endedExam} error={error} testsConfigDict={testsConfigDict} requestLifeCycleTwo={requestLifeCycleTwo || requestLifeCycleThree} pdfUrls={pdfUrls} isDarkTheme={isDarkTheme} updateIsDarkTheme={updateIsDarkTheme} requestLifeCycle={requestLifeCycle} gradedQuestions={gradedQuestions} contents={contentsForCustomExam}/>
            }
            default: {
                return <></>
            }
        }
    }

    useEffect(() => {
        if (currentViewer in TypesOfLocationEnum) {
            const searchParams = new URLSearchParams(location.search);
            searchParams.set("tab", currentViewer)

            navigate(`?${searchParams}`);
        }
    }, [currentViewer])

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const tab = searchParams.get("tab")

        if (tab) {
            setCurrentViewer(tab);
        }

    }, [])

    useEffect(() => {
        
        setStillProcessing(findStillProcessing(gradedQuestions, status));

    }, [status, gradedQuestions])

    return (
        <>
            <Navigation boolTwo={true} bool={false} info={ <NavInfoAssessment name={assessmentInformation.name}/> }/>
            <div className="courses-container" style={{ overflow: "hidden", position: width ? "relative" : "fixed" }}>
                <div className={`single-course-container viewer full ${width ? "small" : "height"}`}>
                    <div className={`assessment-page-content min ${width ? "small" : ""}`}>
                        {
                            currentViewer === TypesOfLocationEnum.SETTINGS

                            ?

                            <>
                                <LeftInfoAssessmentPage status={status} stillProcessing={stillProcessing} endedExam={assessmentInformation?.submission?.endedExam} error={error} testsConfigDict={testsConfigDict} requestLifeCycleTwo={requestLifeCycleTwo || requestLifeCycleThree} pdfUrls={pdfUrls} gradedQuestions={gradedQuestions} isDarkTheme={isDarkTheme} updateIsDarkTheme={updateIsDarkTheme} contents={contentsForCustomExam} requestLifeCycle={requestLifeCycle} assessmentInformation={assessmentInformation}/>
                            </>

                            :

                            <>
                            </>
                        }
                        {
                            currentViewerToReturn()
                        }
                    </div>
                </div>
            </div>
            {
                assessmentInformation.published

                ?
                
                <LocationSettingsHover pdfExists={'submission' in assessmentInformation ? assessmentInformation.submission.pdfExists : false} zipFileExists={'submission' in assessmentInformation ? assessmentInformation.submission.zipFileExists : false} updateCurrentViewer={updateCurrentViewer} isProgramming={assessmentInformation.isProgramming}/>

                :

                <>
                </>
            }
        </>
    );
}

export default AssessmentPage;