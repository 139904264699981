import React, { useState, useEffect } from "react";
import FileEditor from "./fileEditor";
import FileTreeVisualizer from "./fileTreeVisualizer";
import Loading from "./loading";
import useWindowWidth from "./useWindowWidth";

const ZipFileViewerGradingPage = ({ currentFilename, currentFileData, updateCurrentFilename, updateCurrentFileData, isDarkTheme, updateIsDarkTheme, contents, blob, requestLifeCycle }) => {
    const width = useWindowWidth(1000);

    return (
        <>
            <div className={ `pdf-content-container  ${ width ? "small" : "" }` } style={{ minHeight: width ? "800px" : "" , position: "relative"}}>
                <div className="editor-tree-container" style={{ flexDirection: width ? "column" : "", color: isDarkTheme? "var(--vs-icon)" : "var(--almost-black)", backgroundColor: isDarkTheme ? "var(--dark-background)" : "var(--gray-three)" }}>
                    {
                        requestLifeCycle

                        ?

                        <div className="loading-zip-container">
                            <Loading />
                        </div>

                        :

                        <>
                            <FileTreeVisualizer contents={contents} updateCurrentFilename={updateCurrentFilename} updateCurrentFileData={updateCurrentFileData}/>
                            <FileEditor isDarkTheme={isDarkTheme} updateIsDarkTheme={updateIsDarkTheme} blob={blob} currentFileData={currentFileData} currentFilename={currentFilename}/>
                        </>

                    }
                </div>
            </div>
        </>
    );
}

export default ZipFileViewerGradingPage;