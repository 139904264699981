import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loading from "./loading";
import ASingleTestLambdaGrading from "./aSingleTestLambdaGrading";
import Tippy from "@tippyjs/react";

const RunNewTests = ({ status, attemptInput, testsMap, results, uuid, language, allowedAttempts }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { courseCode, assessmentCode } = useParams();
    const [requestLifeCycle, setRequestLifeCycle] = useState(false);
    const [error, setError] = useState(null);
    const [attempt, setAttempt] = useState(attemptInput);
    const [componentStatus, setComponentStatus] = useState(null);

    const setNewParams = () => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set("uuid", uuid);
        navigate(`?${searchParams.toString()}`);
    }
 
    const handleClick = async () => {
        if (requestLifeCycle || (attempt >= allowedAttempts && allowedAttempts !== -1)) {
            return;
        }

        try {
            setRequestLifeCycle(true);
            setError(null);
            const url = process.env.REACT_APP_EVALUATION_API_URL + `/test-question-student/${courseCode}/${assessmentCode}/${uuid}/${language}`
            const urlOptions = {
                method: "POST",
                credentials: "include",                
            }
            
            const response = await fetch(url, urlOptions);
            const data = await response.json();
            if (response.status === 200) {
                setAttempt(prev => prev + 1);
                setNewParams();
                setComponentStatus('RUNNING');

            } else if (response.status === 401) {
                window.location.href = process.env.REACT_APP_401_REDIRECT_URL
            } else {
                setError(data.detail);
                setComponentStatus('COMPLETED');
            }

        } catch (error) {
            console.log(error);
            setError(error.message);
            setComponentStatus('COMPLETED');
        } finally {
            setRequestLifeCycle(false);
        }
    }

    const findRemaining = () => {
        const result = allowedAttempts - attempt;

        if (result < 0) {
            return 0;
        }

        return result;
    }

    const findStatus = () => {
        if (componentStatus === "RUNNING"){
            return " is processing"
        }
    }

    useEffect(() => {
        if (status) {
            setComponentStatus(status);
        }
    }, [status])

    return (
        <>
            {
                requestLifeCycle ?

                <div className="loading-zip-container" style={{ maxHeight: "50px", height: "50px", minHeight: "50px" }}>
                    <Loading />
                </div>

                :

                testsMap && typeof testsMap === "object" && Object.keys(testsMap).length > 0 && (
                    <div className="run-new">
                        <div className="tests-center">
                            <div className={`top-right-item run-tests ${attempt >= allowedAttempts && allowedAttempts !== -1 ? "greyed-out-run-tests" : "" }`} onClick={handleClick} style={{ width: "fit-content", paddingLeft: "10px", paddingRight: "10px" }}>
                                Run Tests {allowedAttempts !== -1 ? `(${findRemaining()} left)` : ""}
                                <Tippy content={<div style={{ fontSize: '12px', textAlign: "center" }}>Running the lambda autograder will evaluate only the tests assigned for this question. Reload periodically for results.</div>} placement={"bottom"}>
                                    <div className="docs-wrapper-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="white" viewBox="0 0 16 16">
                                            <path stroke="currentColor" strokeWidth=".3" d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>
                                        </svg>
                                    </div>
                                </Tippy>
                            </div>
                        </div>
                        {
                            error ?

                            <div style={{ color: componentStatus === "RUNNING" ? "var(--gray-six)" : "" }}>
                                {error}
                            </div>

                            :

                            attempt > 0 && (
                                <>
                                    Additional Attempt {attempt}{allowedAttempts === -1 ? "" : "/" +  allowedAttempts}{findStatus()}:
                                    <div className="true-false-top lambda-results-container">
                                        {
                                            typeof results === "object" && results !== null ? 

                                            Object.keys(results).map((id) => {                            

                                                if (id in testsMap) {
                                                    const test_result = results[id];
                                                    const original_test = testsMap[id];

                                                    return <ASingleTestLambdaGrading greyedOut={componentStatus === "RUNNING"} key={id} testName={original_test.testName} pointsEarned={original_test[test_result.passed ? "passPoints" : "failPoints"]} output={test_result.output} passed={test_result.passed}/>
                                                }
                                            })

                                            :
                                            
                                            <div style={{ color: componentStatus === "RUNNING" ? "var(--gray-six)" : "" }}>
                                                {results}
                                            </div>
                                        }
                                    </div>
                                </>
                            )
                        }
                    </div>
                )
            }
        </>    
    );
}

export default RunNewTests;