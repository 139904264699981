import React from "react";

const Loading = (props) => {
    return (
        <>
            <div className="loading-dots">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
            </div>
        </>
    );
}

export default Loading;