import React from "react";
import useWindowWidth from "./useWindowWidth";

const PDF = (props) => {
    const width = useWindowWidth(1000);
    
    return (
        <>
            <iframe
                src={props.pdf + "#toolbar=0"}
                className="pdf-container-non-multi"
                style={{ borderBottom: width ? "1px solid var(--gray-four)" : "none", minHeight: "600px" }}
                title="PDF Viewer"
            />
        </>
    );
};

export default PDF;