import React, { useState, useEffect } from "react";
import MarkdownWithLaTeX from "./markDownWithLatex";
import Explanation from "./explanation";
import { useLocation, useNavigate } from "react-router-dom";

const TextResponseView = ({ questionsToSave, isDarkTheme, explanation, useExplanation, answerString, questionNumber, question, points, grading, updateQuestionsToSave, uuid, studentAnswer, pointsEarned, answerPassed }) => {
    const [answer, setAnswer] = useState(studentAnswer);
    const [initCall, setInitCall] = useState(true);
    const navigate = useNavigate();
    const locationURL = useLocation();

    function updateAnswer(e) {
        setAnswer(e.target.value);
    }

    
    useEffect(() => {
        // init checking
        if (initCall) {
            setInitCall(false);
            return;
        }
        
        // Create a timer to delay the update by 0.5 seconds
        const timer = setTimeout(() => {
            // Construct new body
            const body = {
                answer: answer
            };

            // Update questions to save
            updateQuestionsToSave((prev) => ({
                ...prev,
                [uuid]: body
            }));
        }, 500); // 500ms delay

        // Clear the timer if `answer` changes again before 0.5 seconds
        return () => clearTimeout(timer);
    }, [answer]);

    useEffect(() => {
        const questionData = questionsToSave ? questionsToSave[uuid] : null

        if (questionData && "answer" in questionData) {
            setAnswer(questionData.answer);
        }

    }, [questionsToSave])

    const setNewParams = () => {        
        const searchParams = new URLSearchParams(locationURL.search);
        searchParams.set("uuid", uuid);
        navigate(`?${searchParams.toString()}`);        
    }

    return (
        <>
            <div className="true-false-question-view" id={uuid} onClick={setNewParams}>
                <div className="question-number">
                    <>
                        Q{ questionNumber }
                    </>
                    <div className="pts">                        
                        { (grading ? Number(pointsEarned).toFixed(2) + " / " : "") + Number(points).toFixed(2) } pts
                    </div>
                </div>
                <div className="true-false-top">
                    <MarkdownWithLaTeX content={question} isDarkTheme={isDarkTheme}/>
                </div>
                <div className="text-response-area">
                    <textarea className="text-response-area-input" value={answer} onChange={updateAnswer} style={{ minHeight: grading ? "10px" : "" }} disabled={grading}/>
                </div>
                {
                    grading && answerString && (
                        <div className="text-response-area">
                            <div className="answer-string">
                                Correct: {answerString}
                            </div>
                        </div>
                    )
                }
                <Explanation grading={grading} useExplanation={useExplanation} explanation={explanation}/>
            </div>
        </>
    );
}

export default TextResponseView;