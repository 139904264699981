import React from "react";
import MarkdownWithLaTeX from "./markDownWithLatex";

const Explanation = ({ useExplanation, explanation, grading }) => {
    return (
        <>
            {
                useExplanation && grading && (
                    <div className="explanation-wrapper">
                        <div className="explanation-wrapper-text">
                            Explanation:
                        </div>
                        <div className="true-false-top">
                            <MarkdownWithLaTeX content={explanation}/>
                        </div>
                    </div>
                )
            }
        </>
    );
}

export default Explanation;