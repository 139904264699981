import React, { useState, useEffect } from "react";
import Editor from "@monaco-editor/react";
import DarkThemeQuestion from "./darkThemeQuestion";
import MarkdownWithLaTeX from "./markDownWithLatex";
import Explanation from "./explanation";
import Loading from "./loading";
import ASingleTestGrading from "./aSingleTestGrading";
import FullScreenToggler from "./fullScreenToggler";
import { useLocation, useNavigate } from "react-router-dom";
import RunNewTestsProgramming from "./runNewTestsProgramming";

const CodeResponseView = ({ fetchError, updateAttempt, coupledProgrammingQuestions, status, allowedAttemptsProgramming, attempt, endedExam, tests, error, testsConfigDict, requestLifeCycleTwo, explanation, useExplanation, useSyntaxHighlighting, updateQuestionsToSave, updateContents, location, contents, useAutocompletion, questionNumber, question, language, isDarkTheme, updateIsDarkTheme, points, grading, uuid, studentAnswer, pointsEarned }) => {
    const [answer, setAnswer] = useState(studentAnswer);
    const [initCall, setInitCall] = useState(true);
    const [fullscreen, setFullscreen] = useState(false);
    const locationURL = useLocation();
    const navigate = useNavigate();

    const handleChange = (value) => {
        if (!grading) {
            updateContents((prev) => ({
                ...prev,
                [location]: {
                    content: value,
                    isDir: false
                }
            }));
        }
    }

    const handleAnswer = (value) => {
        setAnswer(value);
    }

    useEffect(() => {
        // init checking
        if (initCall) {
            setInitCall(false);
            return;
        }

        // Create a timer to delay the update by 0.5 seconds
        const timer = setTimeout(() => {
            // Construct new body
            const body = {
                answer: answer
            };

            // Update questions to save
            if (!grading) {
                updateQuestionsToSave((prev) => ({
                    ...prev,
                    [uuid]: body
                }));
            }
        }, 500); // 500ms delay

        // Clear the timer if `answer` changes again before 0.5 seconds
        return () => clearTimeout(timer);
    }, [answer]);

    const determineIfWeHaveATestToShow = () => {
        const dictLength = testsConfigDict && Object.keys(testsConfigDict).length > 0;

        if (tests && tests.length > 0 && dictLength) {
            for (const key of tests) {
                if (key in testsConfigDict) {
                    return true;
                }
            }
        } else if (error && typeof error === "string") {
            return true;
        }

        return false;
    }

    function updateFullscreen(fullscreen) {
        setFullscreen(fullscreen);
    }

    const setNewParams = () => {        
        const searchParams = new URLSearchParams(locationURL.search);
        searchParams.set("uuid", uuid);
        navigate(`?${searchParams.toString()}`);        
    }    

    return (
        <>
            <div className="true-false-question-view" id={uuid} onClick={setNewParams}>
                <div className="question-number">
                    <>
                        Q{ questionNumber }
                    </>
                    <div className="pts">
                        { (grading ? Number(pointsEarned).toFixed(2) +" / " : "") + Number(points).toFixed(2) } pts
                    </div>
                </div>
                <div className="true-false-top">
                    <MarkdownWithLaTeX content={question} isDarkTheme={isDarkTheme}/>
                </div>
                <div className="code-response-area-wrapper">
                    {
                        requestLifeCycleTwo ?

                        <>
                            <div className="loading-zip-container-2">
                                <Loading />
                            </div>
                        </>

                        :

                        <>
                            <div className={`code-response-area ${fullscreen ? ((locationURL && locationURL.pathname.endsWith("/take-evaluation")) ? "fullscreen-editor" : "fullscreen-editor-side-bar") : ""}`} style={{ backgroundColor: isDarkTheme ? "var(--vs-background)" : "white", color: isDarkTheme ? "white" : "var(--almost-black)" }}>
                                <div className="code-editor-header">
                                    <FullScreenToggler updateFullscreen={updateFullscreen} fullscreen={fullscreen}/>
                                    <DarkThemeQuestion isDarkTheme={isDarkTheme} updateIsDarkTheme={updateIsDarkTheme}/>
                                </div>                        
                                <Editor
                                    language={grading ? language : (useSyntaxHighlighting ? language : "plaintext")}
                                    value={contents && location in contents ? contents[location].content : answer}
                                    theme={ isDarkTheme ? "vs-dark" : "vs"}
                                    onChange={contents && location in contents ? (value) => handleChange(value) : handleAnswer}
                                    options={{
                                        fontFamily: "Consolas, 'Courier New', monospace", // Default font family
                                        fontSize: 14, // Default font size (14px)
                                        tabSize: 8,         // Set tab length to 4 spaces
                                        fontWeight: "500", // Default font weight
                                        suggestOnTriggerCharacters: useAutocompletion,
                                        quickSuggestions: useAutocompletion,
                                        readOnly: grading,
                                        contextmenu: false, // Disable right-click context menu
                                        parameterHints: useAutocompletion, // Disable parameter hints for function calls
                                        autoClosingBrackets: true, // Disable automatic closing brackets
                                    }}
                                />
                                <div className="code-editor-header" />
                            </div>
                        </>
                        
                    }
                </div>
                {
                    grading && endedExam && determineIfWeHaveATestToShow() && (
                        // if there is an error or a test to show, show either based on error
                        <div className="true-false-top lambda-results-container">
                            {                                
                                
                                error ? 
                                
                                <div style={{ color: status === "RUNNING" ? "var(--gray-six)" : "" }}>
                                    {error}
                                </div>

                                :

                                tests.map((id) => {
                                    if (id in testsConfigDict) {
                                        return <ASingleTestGrading greyedOut={status === "RUNNING"} key={id} {...testsConfigDict[id]}/>
                                    }
                                })
                            }
                        </div>
                    )
                }
                {
                    !grading && !fetchError && allowedAttemptsProgramming !== null && allowedAttemptsProgramming !== undefined && allowedAttemptsProgramming !== 0 && tests && Object.keys(tests).length > 0 && attempt > 0 && (
                        <div className="select-all-that-apply-array" style={{ marginTop: "10px" }}>                            
                            <RunNewTestsProgramming error={error} uuid={uuid} updateAttempt={updateAttempt} coupledProgrammingQuestions={coupledProgrammingQuestions} status={status} attempt={attempt} allowedAttemptsProgramming={allowedAttemptsProgramming ? allowedAttemptsProgramming : 0} tests={tests} testsConfigDict={testsConfigDict}/>
                        </div>                    
                    )
                }
                {
                    fetchError && (
                        <div className="select-all-that-apply-array" style={{ marginTop: "10px" }}>
                            {fetchError}
                        </div>
                    )
                }
                <Explanation grading={grading} useExplanation={useExplanation} explanation={explanation}/>
            </div>
        </>
    );
}

export default CodeResponseView;