import React, { useEffect, useState } from 'react';
import moment from 'moment';

const CountdownTimer = ({ times, updateAutoSubmit, autoSubmit }) => {
    const targetDate = moment.utc(times.finalTime);
    const [showAutosubmitWarning, setShowAutosubmitWarning] = useState(true);
    const [timeRemaining, setTimeRemaining] = useState({
        days: -1,
        hours: -1,
        minutes: -1,
        seconds: -1,
    });

    // Countdown Timer Effect
    useEffect(() => {
        const timer = setInterval(() => {
            const now = moment.utc();
            const duration = moment.duration(targetDate.diff(now));
            const asSeconds = duration.asSeconds();

            if (asSeconds <= 0 && timeRemaining.days !== -1 && !autoSubmit) {
                clearInterval(timer);
                setTimeRemaining({ days: 0, hours: 0, minutes: 0, seconds: 0 });

                updateAutoSubmit(true)
                return; // Early return if time has expired
            }

            setTimeRemaining({
                days: Math.floor(duration.asDays()),
                hours: duration.hours(),
                minutes: duration.minutes(),
                seconds: duration.seconds(),
            });

            // Check for 3-minute warning
            if (asSeconds <= 120 && showAutosubmitWarning && timeRemaining.days !== -1) {
                alert("2 minutes remaining. Your evaluation will autosubmit when time expires.")
                setShowAutosubmitWarning(false);
            }
        }, 1000);

        // Cleanup on component unmount
        return () => clearInterval(timer);
    }, [timeRemaining]); // Add dependencies

    return (
        <>
            <div className="timer">
                {
                    times &&
                    timeRemaining && 
                    (timeRemaining.days > 0 || 
                    timeRemaining.hours > 0 || 
                    timeRemaining.minutes > 0 || 
                    timeRemaining.seconds > 0) && (
                        <>
                            {timeRemaining.days > 0 && `${timeRemaining.days.toString().padStart(2, '0')}:`}
                            {(timeRemaining.days > 0 || timeRemaining.hours > 0) && `${timeRemaining.hours.toString().padStart(2, '0')}:`}
                            {(timeRemaining.days > 0 || timeRemaining.hours > 0 || timeRemaining.minutes > 0) && `${timeRemaining.minutes.toString().padStart(2, '0')}:`}
                            {timeRemaining.seconds.toString().padStart(2, '0')}
                        </>
                    )
                }
            </div>
        </>
    );
};

export default CountdownTimer;
